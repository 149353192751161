@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
/* nunito-900 - latin */

body {
  margin: 0px;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 100%;
}
.ant-input:hover {
  border-color: rgb(250, 196, 43);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: rgb(250, 196, 43);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgb(250, 196, 43, 0.4);
}

.ant-input {
  border: 1px solid #8492a6;
  box-sizing: border-box;
  height: 45px;
  border-radius: 5px;
}

.ant-select-selection-item {
  line-height: 45px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(250, 196, 43);
}

.ant-checkbox-checked::after {
  border: 1px solid rgb(250, 196, 43);
}
.ant-input-affix-wrapper:hover {
  border-color: rgb(250, 196, 43);
}

body {
  background-color:#1f2021;
}

.Home_middle__2MhQK {
  padding-left: 5.7%;
  padding-right: 5.7%;
  padding-top: 3%;
  background-color:#1f2021;
}

.TopNavigation_NavbarSearch__3ZUVx {
  margin-left: auto;
  width: 35%;
  border: 3px solid white;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: #484848;
  height: 40px;
  align-items: center;
}

.TopNavigation_NavbarSearch__3ZUVx input{
  background-color: rgba(0,0,0,0);
  color: white;
} 

.TopNavigation_NavbarHow__34auE {
  font-weight:600;
  font-size: 16px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  width: 270px;
  letter-spacing: -0.02em;
  text-align: center;
  vertical-align: middle;
  color: white;
}

.TopNavigation_logo__1cobK {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 40px;
  text-transform: capitalize;
  vertical-align: middle;
  color: #000000;
}

.TopNavigation_logoimg__27Tas {
  height: 40px;
  vertical-align: middle;
  margin-left: 20px;
}

.TopNavigation_Header__eHrP2 {
  text-align: center;
  background-color:#1f2021;
  z-index: 9;
}

.TopNavigation_Prefix__1JD0m {
  color: white;
  font-size: 26px;
}

.TopNavigation_HamburgerIcon__I6FX4 {
  color: white;
  font-size: 28px;
}

.TopNavigation_navbarcollapsecustom__3BmMn{
  flex-grow: 0 !important;
}


@media only screen and (max-width: 768px) {
  .TopNavigation_navbarcollapsecustom__3BmMn{
    flex-grow: 1;
  }
  
  .TopNavigation_logoimg__27Tas {
    height: 40px;
    vertical-align: middle;
    margin-left: 0px;
  }
}
.CardRow_CardRow__BGmbI {
  margin-top: 4%;
  margin-bottom: 4%;
}

.CardRow_Title__1i1ko {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:white;
}

.CardRow_More__1h21f {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: rgba(255, 255, 255, 1);
  margin-left: auto;
  margin-top: 5px;
}

.CardRow_Top__1KhNW {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.CardRow_Cards__uHZsC {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.CardRow_Card__3AesD {
  flex: 0 0 auto;
}

.CardRow_Cards__uHZsC .CardRow_Card__3AesD:not(:last-child) {
  margin-right: 15px;
}

.Card_Card__nPa-c {
  width: 184px;
  height: 240px;
  display: flex;
  flex-direction: column;
  border-width: 4px;
  background-color: #1f2021;
  border-color: #1f2021;
}

.Card_ImageWrapper__1fKUy {
  height: 77%;
  position: relative;
}

.Card_Image__3qiLs {
  min-width: 184px;
  max-width: 184px;
  border-radius: 10px 10px 10px 10px;
  background-color: #1f2021;
}

.Card_hart__IXSxO {
  position: absolute;
  text-align: center;
  min-width: 4rem;
  right: 0.1rem;
  top: 0.1rem;
  width: 20px;
  height: 60px;
}

.Card_Box__nFODX {
  width: 101%;
  height: 23%;
  background-color: #1f2021;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card_Name__2lsG- {
  font-style: normal;
  font-weight: 800;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif;
  font-size: 18px;
  line-height: 20px;
  text-transform: capitalize;
  margin-top: 4%;
  margin-left: 1%;
  margin-bottom: 5px;
  color: white;
}

.Card_Info__1CSNj {
  font-style: normal;
  font-weight: 900;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin-left: 1%;
  height: 50px;
  background-color: #1f2021;
  text-transform: capitalize;
  color: rgb(173, 174, 181);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Card_Price__3d577 {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #ffffff;
  position: absolute;
  text-align: center;
  min-width: 4rem;
  right: 0.3rem;
  bottom: 0.4rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

/* @media only screen and (max-width: 1600px) {
  .Card {
    background-color: transparent;
  }
} */

.WhatIs_IconGrote__346Vp {
    color: rgb(151, 151, 167);
    font-size: 100px;
    line-height: 140px;
    margin-bottom: 50px;
    margin-top: 50px;
}

.WhatIs_IconHand__26J4q {
    color: rgb(151, 151, 167);
    font-size: 30px;
    line-height: 140px;
    margin-top: 100px;
}

.WhatIs_Columns__1IJ7S {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
}

.WhatIs_Row__NYQyI {
    flex: 1 1;
    flex-direction: column;
}

.WhatIs_Text__2yJPZ {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
  
    text-align: center;
    vertical-align: middle;
    color: white;
}

@media only screen and (max-width: 768px) {
    .WhatIs_IconGrote__346Vp {
        color: rgb(151, 151, 167);
        font-size: 66px;
        line-height: 93px;
        margin-bottom: 33px;
        margin-top: 33px;
    }

    .WhatIs_IconHand__26J4q {
        color: rgb(151, 151, 167);
        font-size: 20px;
        line-height: 93px;
        margin-top: 66px;
    }

    .WhatIs_Text__2yJPZ {
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 13px;
        text-align: center;
        vertical-align: middle;
        color: white;
    }
}
.Footer_Footer__IrRCd {
  background-color: #161616;
  padding: 4% 5.7% 4% 5.7%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 100%;
}

.Footer_Image__1meb-{
  height: 17px;
  width: 17px;
}

.Footer_FooterMain__l9X06 {
  background-color: #161616;
  padding: 4% 5.7% 4% 5.7%;
  flex-direction: column;
  justify-content: space-between;
}

.Footer_Mail__3pnGy {
  flex: 1 1;
  color: white;
}

.Footer_Empty__1Xup9 {
  flex: 1 1;
  color:white;
}

.Footer_Info__1B2yH {
  flex: 1 1;
  border-left: solid 1px;
  padding-top: 1%;
  padding-left: 3%;
  color:white;
}

.Footer_MailIcon__3ezGW{
  color:white;
}

.Footer_InputMail__d419D {
  border: 1px solid  #484848;;
  border-radius: 10px;
  background-color: #161616;
  color: white;
  align-items: center;

}
.Footer_InputMail__d419D input{
  background-color: rgba(0,0,0,0);
  color: white;
} 

.Footer_ArrowButton__2-EMJ {
  color: white;
  padding-bottom: 8px;
}

.Footer_Row__1MoHb {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
}
.Footer_PressButton__3S5wG{
  background-color: #161616;
  text-align: center;
  border: 1px solid  #484848;;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  margin-left: 20px;
} 

.Footer_PressButtonText__2pZ9o{
  background-color: #161616;
  text-align: center;
  border: 1px solid  #484848;;
  border-radius: 10px;
  height: 60px;
  widows: 300px;
  align-self: center;
  justify-content: center;
  text-align: center;
  font-size: 26px;
  color: white;
} 

.Footer_Joinen__3DA0F{
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .Footer_Footer__IrRCd {
    background-color: #161616;
    padding: 4% 5.7% 4% 5.7%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 100%;
  }

  .Footer_Joinen__3DA0F{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .Footer_Mail__3pnGy {
    flex: 1 1;
    color: white;
    margin-bottom: 50px;
  }

  .Footer_Info__1B2yH {
    flex: 1 1;
    border-left: solid 1px;
    padding-top: 1%;
    padding-left: 3%;
    color:white;
    font-size: 14px;
  }
}
.Star_middle__3Pe29 {
  padding-left: 5.8%;
  padding-right: 5.8%;
  padding-top: 3%;
  background-color:#1f2021;
}

@media only screen and (max-width: 768px) {
  .Star_middle__3Pe29 {
    padding-left: 5.8%;
    padding-right: 5.8%;
    padding-top: 3%;
    background-color:#1f2021;
  }   
}

@media only screen and (max-width: 1000px) {
  .Star_middle__3Pe29 {
    padding-left: 5.8%;
    padding-right: 5.8%;
    padding-top: 3%;
    background-color:#1f2021;
  }
}

.Info_Rows__1zYks {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 150px;
  
}

.Info_Button__ni7Bt {
  height: 50px;
}

.Info_Text__1XMKE {
  flex-direction: column;
  margin-left: 30px;
  margin-right: 10px;
  max-height: 500px;
  max-width: 400px;
}

.Info_Columns__Gb00t {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Info_ColumnsGoedDoel__2Mzmu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-right: 0.1%;
  margin-left: 2%;
  min-width: 250px;
}

.Info_InfoText__2o04f {
  margin-left: 25px;
  width: 380px;
  min-width: 300px;
  overflow: auto;
  text-overflow: ellipsis;
  max-height: 622px;
  max-width: 400px;
}

.Info_StarName__3UFj3 {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white;
}

.Info_StarShow__XyCnN {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: rgb(173, 174, 181);
}

.Info_StarInfo__1NyZQ {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 20px;
  color:white;
  margin-bottom: 75px;
}

.Info_YellowButton__32e3M {
  margin-top: 0px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .Info_Rows__1zYks {
    flex-direction: column;
    margin-bottom: 150px;
    width: 100%;
    align-items: center;
    padding-right: 5%;
  }

  .Info_Columns__Gb00t {
    width: 100%;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
  }

  .Info_StarInfo__1NyZQ {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
  }

  .Info_YellowButton__32e3M {
    margin-top: 50px;
    text-align: center;
    width: 100%;
  }
  
  .Info_InfoText__2o04f {
    margin-left: 25px;
    width: 380px;
  }

  .Info_ColumnsGoedDoel__2Mzmu {
    margin-top: 50px;
    width: 100%;
    align-items: center;
  }

  .Info_Text__1XMKE {
    flex-direction: column;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .Info_StarInfo__1NyZQ {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
  }
  .Info_InfoText__2o04f {
    margin-left: 25px;
    width: 300px;
    min-width: 250px;
    overflow: auto;
    text-overflow: ellipsis;
    height: 500px;
  }
}
.YellowButton_Button__3-xIQ {
  color: #ffffff !important;
  text-shadow: rgba(0,0,0,.12);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  background: #fac42b !important;
  border-radius: 22px;
  border-color: transparent !important;
  height: 44px;
  width: 100%;
  justify-self: flex-end;
  justify-content: center;
}

.InfoVideocard_Card__vCHVU {
  height: 622px;
  width: 350px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.InfoVideocard_Video__2Hdkl {
  height: 622px;
  width: 350px;
  border-radius: 20px;
  background-color: black;
}

@media only screen and (max-width: 1000px) {
  .InfoVideocard_Card__vCHVU {
    height: 500px;
    width: 281.35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .InfoVideocard_Video__2Hdkl {
    height: 500px;
    width: 281.35px;
    border-radius: 20px;
    background-color: black;
  }
}
  
.InfoGoedDoel_OrderInfo__1fHNs {
    width: 100%;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 50px;
  }
  
  .InfoGoedDoel_List__1ibTv {
    flex-direction: column;
    padding: 20px;
    align-items: center;
    text-align: center;
    overflow: auto;
    text-overflow: ellipsis;
  }
  
  .InfoGoedDoel_ListItem__3Cvw5 {
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:black;
    text-align: center;
    min-width: 150px;
  }

  .InfoGoedDoel_ListItemLink__1gNsX {
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:#fac42b;
    text-align: center;
  }
  
  .InfoGoedDoel_ListItemGrijs__gXHcq {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
    color:gray
  }
  
  .InfoGoedDoel_ListItemSpatie__3JgwY {
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
    height: 20px;
    color:black
  }
  
  .InfoGoedDoel_bancontact__1-bbP {
    width: 50px;
    height: 50px;
    align-self: center;
  }
  
  .InfoGoedDoel_visa__3gobJ{
    width: 50px;
    height: 50px;
    align-self: center;
    margin-right: 10px;
  }
  
  .InfoGoedDoel_mastercard__3fIs1 {
    width: 40px;
    height: 30px;
    align-self: center;
    margin-right: 10px;
  }
  
  .InfoGoedDoel_amex__KKv6j {
    width: 40px;
    height: 40px;
    align-self: center;
    background-color: transparent;
  }

  .InfoGoedDoel_hart__JUpf7 {
    position: relative;
    text-align: center;
    width: 30px;
    height: 40px;
    padding-bottom: 10px;
  }

@media only screen and (max-width: 768px) {
    .InfoGoedDoel_OrderInfo__1fHNs {
        width: 100%;
        border-radius: 15px;
        background-color: white;
        margin-bottom: 50px;
        align-self: center;
        }
}
.FAQPage_middle__14Ccy {
  padding-left: 13.8%;
  padding-right: 13.8%;
  padding-top: 3%;
  background-color:#1f2021;
}

@media only screen and (max-width: 768px) {
.FAQPage_middle__14Ccy {
  padding-left: 8.8%;
  padding-right: 8.8%;
  padding-top: 3%;
  background-color:#1f2021;
}
}
.TopNavigationLandingPage_NavbarSearch__1yfJf {
  margin-left: auto;
  width: 35%;
  border: 3px solid white;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: #484848;
  height: 40px;
  align-items: center;
}

.TopNavigationLandingPage_NavbarSearch__1yfJf input{
  background-color: rgba(0,0,0,0);
  color: white;
} 

.TopNavigationLandingPage_Contact__3osHo {
  background-color: #fac42b;
  padding: 13px;
  border-radius: 100px;
  padding-left: 40px;
  padding-right: 40px;
  font-weight: 700;
  color: white;
  font-size: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  width: 80px;
  letter-spacing: -0.02em;
  text-align: center;
  vertical-align: middle;
  margin-left: 0px;
  margin-top: 30px;
}

.TopNavigationLandingPage_NavbarHow__2FO1k {
  font-weight:600;
  font-size: 20px;
  line-height: 25px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  width: 270px;
  /* letter-spacing: -0.02em; */
  vertical-align: center;
  color: white;
  padding: 20px;
}

.TopNavigationLandingPage_logo__uE0zo {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 40px;
  text-transform: capitalize;
  vertical-align: middle;
  color: #000000;
}

.TopNavigationLandingPage_logoimg__1ZV8U {
  height: 50px;
  vertical-align: middle;
  margin-left: 20px;
  margin-top: 15px;
}

.TopNavigationLandingPage_Header__3TUHU {
  background-color:#191a1b;
  z-index: 9;
  text-align: center;
}

.TopNavigationLandingPage_Prefix__2RVim {
  color: white;
  font-size: 26px;
}

.TopNavigationLandingPage_HamburgerIcon__3F_Ov {
  color: white;
  font-size: 28px;
}

.TopNavigationLandingPage_navbarcollapsecustom__1d_6L{
  flex-grow: 0 !important;
}


@media only screen and (max-width: 768px) {
  .TopNavigationLandingPage_navbarcollapsecustom__1d_6L{
    flex-grow: 1;
  }
  
  .TopNavigationLandingPage_logoimg__1ZV8U {
    height: 50px;
    vertical-align: middle;
    margin-left: 0px;
  }

  .TopNavigationLandingPage_NavbarHow__2FO1k {
    font-weight:600;
    font-size: 20px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    width: 270px;
    /* letter-spacing: -0.02em; */
    text-align: center;
    vertical-align: middle;
    color: white;
    padding: 20px;
  }

  .TopNavigationLandingPage_Contact__3osHo {
    background-color:transparent;
    font-weight:600;
    font-size: 20px;
    line-height: 25px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    width: 270px;
    /* letter-spacing: -0.02em; */
    vertical-align: center;
    color: white;
    padding: 10px;
    margin-top: 200px;
  }
}
.Faq_Columns__1kqlz {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color:#1f2021;
}

.Faq_Title__ietV_ {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    /* text-transform: capitalize; */
    color: white;
}

.Faq_Subtitle__2SPFy {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
    /* text-transform: capitalize; */
    color: white;
}

.Faq_InfoText__1I6yN {
    width: 80%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
}

.Faq_InfoTextBold__2lSLG {
    width: 80%;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
}

.Faq_TextLink__isem3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px !important;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: #fac42b !important;
  }

@media only screen and (max-width: 768px) {
    .Faq_InfoText__1I6yN {
        width: 100%;
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-top: 20px;
        color:white;
        margin-bottom: 75px;
    }
}

.VideoPage_middle__2THxO {
    padding-left: 5.8%;
    padding-right: 5.8%;
    padding-top: 3%;
    background-color:#1f2021;
  }
.VideoDone_Rows__1Y8r5 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 150px;
  background-color: #484848;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid  white;
  box-shadow: blue;
}
.VideoDone_Stars__2fGkE {
  margin: 4px;

}
.VideoDone_Stars1__20Y7F {
  margin-right: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.VideoDone_TextArea__3HKQ7 {
  height: 0px;
  width: 0px;
}

.VideoDone_Icons__3GI6D {
  margin: 10px;
}

.VideoDone_Icons1__3DQC0 {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.VideoDone_Columns__3smwx {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.VideoDone_ColumnsReview__1fOKK {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: 25px;
  width: 400px;
  overflow: auto;
  text-overflow: ellipsis;
}

.VideoDone_Image__2aUKq {
  width: 340px;
  height: 450px;
  border-radius: 20px;
}

.VideoDone_Rating__P2VMk {
  margin-top: 20px;
  margin-left: auto;
}

.VideoDone_InfoText__YljF- {
  margin-left: 25px;
  width: 400px;
  overflow: auto;
  text-overflow: ellipsis;
}

.VideoDone_InfoStars__38p0_ {
  width: 400px;
  overflow: auto;
  text-overflow: ellipsis;
}

.VideoDone_StarName__18M4P {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white;
}

.VideoDone_StarShow__3Jek5 {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: rgb(173, 174, 181);
  margin-bottom: 5px;
}

.VideoDone_StarInfo__267fR {
  height: 215px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  /* margin-top: 20px; */
  color:white;
  margin-bottom: 75px;
}

.VideoDone_YellowButton__3BjjC {
  margin-top: 20px;
  width: 40%;
  text-align: center;
  margin-left: 60%;
}

.VideoDone_Input__32AYT {
  font-size: 17px!important;
  height: 100px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .VideoDone_Rows__1Y8r5 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 150px;
    align-items: center;
    border: 0px ;
    width: 100%;
    padding-left: 5px;
  }

  .VideoDone_Columns__3smwx {
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  .VideoDone_ColumnsReview__1fOKK {
    margin-bottom: 50px;
    width: 100%
  }

  .VideoDone_StarName__18M4P {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: white;
    text-align: center;
  }

  .VideoDone_StarInfo__267fR {
    height:180px;
    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
  }

  .VideoDone_YellowButton__3BjjC {
    margin-top: 20px;
    width: 40%;
    text-align: center;
    margin-left: 60%;
  }
  
  .VideoDone_InfoStars__38p0_ {
    width: 100%;
    overflow: auto;
    text-overflow: ellipsis;
    text-align: center;
  }

  .VideoDone_StarShow__3Jek5 {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 33px;
    text-transform: capitalize;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: rgb(173, 174, 181);
    text-align: center;
  }

  .VideoDone_Icons__3GI6D {
    margin-right: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
}
.VideoCardDone_Card__26BMf {
  height: 500px;
  width: 281.35px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: black;
  margin-left: 20px;
}

.VideoCardDone_Video__3RM3R {
  height: 500px;
  width: 281.35px;
  border-radius: 20px;
  background-color: black;
}


.IntroVideoAppPage_middle__2mBuG {
    padding-left: 5.8%;
    padding-right: 5.8%;
    padding-top: 3%;
    background-color:#1f2021;
  }
.IntroVideoApp_Rows__1JeSy {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 150px;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid  white;
}

.IntroVideoApp_Columns__2Lyq9 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-right: 20%;
}

.IntroVideoApp_StarName__2Ext_ {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: black;
}

@media only screen and (max-width: 768px) {
  .IntroVideoApp_Rows__1JeSy {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 150px;
    align-items: center;
    border: 0px ;
    width: 100%;
    padding-left: 5px;
  }

  .IntroVideoApp_Columns__2Lyq9 {
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 50px;
    margin-right: 0;
    margin-left: 6%;
  }

  .IntroVideoApp_StarName__2Ext_ {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: black;
    text-align: center;
    margin-left: 2%;
  }
  
  .IntroVideoApp_InfoStars__2ZuLA {
    width: 100%;
    overflow: auto;
    text-overflow: ellipsis;
    text-align: center;
  }
}
.IntroVideoAppVideo_Card__14hye {
  height: 622px;
  width: 350px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.IntroVideoAppVideo_Video__14ey3 {
  height: 622px;
  width: 350px;
  border-radius: 20px;
  background-color: black;
}

@media only screen and (max-width: 1000px) {
  .IntroVideoAppVideo_Card__14hye {
    height: 500px;
    width: 281.35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .IntroVideoAppVideo_Video__14ey3 {
    height: 500px;
    width: 281.35px;
    border-radius: 20px;
    background-color: black;
  }
}
  
.HowPage_middle__2UuD0 {
    padding-left: 13.8%;
    padding-right: 13.8%;
    padding-top: 3%;
    background-color:#1f2021;
}

@media only screen and (max-width: 768px) {
  .HowPage_middle__2UuD0 {
    padding-left: 8.8%;
    padding-right: 8.8%;
    padding-top: 3%;
    background-color:#1f2021;
  }
}

.How_Row__3_eSP {
  flex-direction: column;
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
}

.How_Columns__3CXp_ {
  flex-direction: row;
  display: flex;
  border: 5px solid  white;
  margin-bottom: 20px;
  border-radius: 30px;
}

.How_Div1__1RAt3 {
  margin: auto;
  width: 70%;
  height: 100%;
  padding-left: 15%;
  padding:50px;
}

.How_Div2__3ajWP {
  margin: auto;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.How_IconGrote__3UlP- {
  color: white;
  font-size: 160px;
}

.How_Text__12p14 {
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 100px;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  justify-self: center;
  color: white;
}

.How_Hoofding__1uf0i {
  width: 80%;
  font-weight: 800;
  font-size: 30px;
  line-height: 32px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 20px;
  color:white;
  margin-bottom: 25px;
}

@media only screen and (max-width: 768px) {
  .How_Row__3_eSP {
    flex-direction: column;
    width: 100%;
    display: flex;
    flex-wrap: nowrap; 
  }
  
  .How_Columns__3CXp_ {
    flex-direction: row-reverse;
    display: flex;
    border: 5px solid  white;
    margin-bottom: 20px;
    border-radius: 30px;
  }
  
  .How_Div1__1RAt3 {
    margin: auto;
    width: 50%;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .How_Div2__3ajWP {
    margin: auto;
    width: 100%;
    height: 100%;
    padding-left: 10%;
  }
  
  .How_IconGrote__3UlP- {
    color: white;
    font-size: 80px;
  }
  
  .How_Text__12p14 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 100px;
    text-align: center;
    vertical-align: middle;
    align-self: center;
    justify-self: center;
    color: white;
  }
  
  .How_Hoofding__1uf0i {
    width: 80%;
    font-weight: 800;
    font-size: 30px;
    line-height: 32px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 25px;
  }
}

.PrivacyPage_middle__3u5cP {
  margin-left: 13.8%;
  margin-right: 13.8%;
  margin-top: 3%;
}
.Privacy_Columns__-_Z_Q {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Privacy_Title__39wXI {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-transform: capitalize;
  color: black;
}

.Privacy_Subtitle__I9UQq {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: black;
}

.Privacy_DatumText__NM0TU {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: gray;
}

.Privacy_InfoText__QXK0b {
  width: 80%;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 20px;
  color:black;
  margin-bottom: 75px;
}

.Privacy_TextLink__2vvfO {
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: #fac42b !important;
}

@media only screen and (max-width: 768px) {
  .Privacy_InfoText__QXK0b {
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:black;
    margin-bottom: 75px;
  }
}

.SecretPage_middle__3AWvC {
  padding-left: 13.8%;
  padding-right: 13.8%;
  padding-top: 3%;
  background-color:#1f2021;
  height: 1500px;
  text-align: center;
}

.SecretPage_Image__3gYO- {
  margin-top: 200px;
  text-align: center;
}

.SecretPage_Button__3YIZh {
  background-color: transparent;
  border: 0px;
}

.SecretPage_Input__9QQFl {
  margin-top: 20px;
  border-radius: 5px;
  font-size: large;
  font-weight: 700;
  background-color: gray;
  border-radius: 20px;
  border: 3px solid white;
  color: white;
  width: 200px;
}
.TopBlockSecretPage_Background__287M8 {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    /* background-color:#1f2021; */
    background-color:#1f2021;
    align-items: center;
    height: 600px;
    padding-bottom: 100px;
}

.TopBlockSecretPage_Frame1__3bMBu {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    /* background-color:#1f2021; */
    background-color:#1f2021;
    height: 500px;
    align-items: center;
}

.TopBlockSecretPage_Row1__UqL5c {
    flex: 1 1;
    width: 90%;
    display: flex;
    margin-left: 50px;
    height: 30%;
    margin-top: 10px;
    align-items: center;
    vertical-align: center;
}

.TopBlockSecretPage_Row3__1Db9z {
    flex: 1 1;
    width: 90%;
    display: flex;
    margin-left: 50px;
    height: 30%;
    margin-top: 10px;
    align-items: center;
    vertical-align: center;
    /* background-color: red; */
}

.TopBlockSecretPage_Row4__2Yr2Q {
    flex: 1 1;
    width: 90%;
    display: flex;
    margin-left: 50px;
    height: 30%;
    /* margin-top: 10px; */
    align-items: center;
    vertical-align: center;
    /* background-color: blue; */
}

.TopBlockSecretPage_Column1__m3Zco {
    flex: 1 1;
    height: 340px;
    /* background-color: blue; */
}

.TopBlockSecretPage_Column2__18DDj {
    height: 340px;
    align-items: center;
    padding-right: 100px;
}

.TopBlockSecretPage_Text1__3cJdV {
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
    text-align: left;
    vertical-align: middle;
    color: white;
    padding: 20px;
}

.TopBlockSecretPage_Text2__2Qhkt {
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
    text-align: left;
    vertical-align: middle;
    color: white;
    padding: 20px;
}

.TopBlockSecretPage_InviteCodeText__1fny_ {
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 60px;
    text-align: left;
    vertical-align: middle;
    color: white;
    padding-left: 20px;
}

.TopBlockSecretPage_InviteCodeTextBig__2o-3z {
    font-weight: 800;
    font-size: 20px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    text-align: left;
    color: white;
}

.TopBlockSecretPage_Image1__3SPAr {
    height: 68px;
    background-color: #1f2021;
    margin-left: 20px;
}

.TopBlockSecretPage_Image2__3iWqc {
    height: 100px;
    background-color: #1f2021;
}

.TopBlockSecretPage_Image3__3P0vR {
    height: 400px;
    text-align: left;
}

.TopBlockSecretPage_Video__JCtEx {
    height: 281px;
    background-color: black;
    position: absolute;
    bottom: 368px;
    width: 460px;
    left: 63px;
}

.TopBlockSecretPage_VideoScreen__baZ4c {
    height: 281px;
    background-color: black;
    position: absolute;
}

@media only screen and (max-width: 768px) {
    .TopBlockSecretPage_Frame1__3bMBu {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        height: 500px;
        align-items: center;
    }

    .TopBlockSecretPage_Column1__m3Zco {
        flex: 1 1;
        height: 340px;
        /* background-color: blue; */
    }

    .TopBlockSecretPage_Text1__3cJdV {
        font-weight: 800;
        font-size: 40px;
        line-height: 42px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 70px;
        text-align: center;
        vertical-align: middle;
        color: white;
        padding: 50px;
    }

    .TopBlockSecretPage_Text2__2Qhkt {
        font-weight: 300;
        font-size: 22px;
        line-height: 28px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 20px;
        text-align: center;
        vertical-align: middle;
        color: white;
        padding: 20px;
    }

    .TopBlockSecretPage_Image1__3SPAr {
        height: 45px;
        align-self: center;
    }
    
    .TopBlockSecretPage_Image2__3iWqc {
        height: 66px;
        background-color: #1f2021;
    }

    .TopBlockSecretPage_Row1__UqL5c {
        flex: 1 1;
        width: 80%;
        display: flex;
        margin-left: 0px;
        height: 30%;
        margin-top: 10px;
        align-items: center;
        vertical-align: center;
        text-align: center;
        /* background-color: red; */
    }

    .TopBlockSecretPage_Row3__1Db9z {
        flex: 1 1;
        width: 100%;
        display: flex;
        margin-left: 0;
        height: 30%;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        /* background-color: aqua; */
    }

    .TopBlockSecretPage_Row4__2Yr2Q {
        flex: 1 1;
        width: 100%;
        display: flex;
        margin-left: 0;
        height: 30%;
        align-items: center;
        justify-content: center;
        /* background-color: aqua; */
        padding-bottom: 50px;
    }

    .TopBlockSecretPage_Badge1__YSQCZ {
        flex:1 1;
        /* background-color: aliceblue; */

    }

    .TopBlockSecretPage_Badge2__3T1SC {
        flex:1 1;
        align-self: center;
        /* background-color: blue; */
    }

    .TopBlockSecretPage_Row1__UqL5c {
        flex: 1 1;
        width: 100%;
        display: flex;
        height: 30%;
        margin-top: 10px;
        align-items: center;
        vertical-align: center;
    }

    .TopBlockSecretPage_Video__JCtEx {
        height: 180px;
        background-color: black;
        position: absolute;
       bottom: 335px;
       width: 284px;
       left: 42px;
    }
    
    .TopBlockSecretPage_VideoScreen__baZ4c {
        height: 281px;
        background-color: black;
        position: absolute;
        
    }

    .TopBlockSecretPage_Column2__18DDj {
        height: 340px;
        align-items: center;
        padding: 20px;
        padding-bottom: 100px;
        /* background-color: aqua; */
    }

    .TopBlockSecretPage_Image3__3P0vR {
        height: 250px;
        text-align: left;
        /* background-color: red; */
    }

    .TopBlockSecretPage_Background__287M8 {
        width: 100%;
        display: flex;
        flex-direction: row;
        text-align: center;
        /* background-color:#1f2021; */
        background-color:#1f2021;
        align-items: center;
        height: 700px;
    }

    .TopBlockSecretPage_InviteCodeText__1fny_ {
        font-weight: 300;
        font-size: 17px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 60px;
        text-align: right;
        vertical-align: middle;
        color: white;
        padding-left: 20px;
    }
    
    .TopBlockSecretPage_InviteCodeTextBig__2o-3z {
        font-weight: 800;
        font-size: 20px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        text-align: right;
        color: white;
    }
}
.MiddleBlockSecretPage_Background__3hfNQ {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color:#1f2021;
    padding-bottom: 100px;
}

.MiddleBlockSecretPage_Column1__1YBVS {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color:#1f2021;
    padding-left: 70px;
    padding-right: 100px;
}

.MiddleBlockSecretPage_RowIcon__1j9A6{
    /* background-color: blue; */
    margin-bottom: 20px;
}

.MiddleBlockSecretPage_IconImage__Z2yYp {
    height: 50px;
    text-align: left;
}

.MiddleBlockSecretPage_RowTitle__2I7C_{
    /* background-color: red; */
    margin-bottom: 20px;
    height: 50px;
}

.MiddleBlockSecretPage_RowText__1o77H{
    /* background-color: yellow; */
    text-align: left;

}

.MiddleBlockSecretPage_Column2__guF8k {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color:#1f2021;
    padding-right: 100px;
}

.MiddleBlockSecretPage_Column3__ov46L {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color:#1f2021;
    padding-right: 70px;
}

.MiddleBlockSecretPage_TitleText__36E11 {
    font-weight: 800;
    font-size: 30px;
    line-height: 32px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
    text-align: left;
    vertical-align: middle;
    color: white;
}

.MiddleBlockSecretPage_NormalText__3tzPN {
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
    text-align: left;
    vertical-align: middle;
    color: white;
}

@media only screen and (max-width: 768px) {
    .MiddleBlockSecretPage_Background__3hfNQ {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        align-items: center;
        /* height: 800px; */
        padding-top: 000px;
        padding-bottom: 1px;
    }
    
    .MiddleBlockSecretPage_RowIcon__1j9A6{
        /* background-color: blue; */
        flex: 1 1;
        margin-bottom: 20px;
    }
    
    .MiddleBlockSecretPage_IconImage__Z2yYp {
        height: 50px;
    }
    
    .MiddleBlockSecretPage_RowTitle__2I7C_{
        /* background-color: red; */
        flex: 1 1;
        margin-bottom: 20px;
    }
    
    .MiddleBlockSecretPage_RowText__1o77H{
        /* background-color: yellow; */
        flex: 1 1;
        text-align: center;
        min-width: 300px;
    }

    .MiddleBlockSecretPage_Column1__1YBVS {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        align-self: center;
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 60px;
    }
    
    .MiddleBlockSecretPage_Column2__guF8k {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        align-self: center;
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 60px;
    }
    .MiddleBlockSecretPage_Column3__ov46L {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        align-self: center;
        padding-right: 30px;
        padding-left: 30px;
    }
    
    .MiddleBlockSecretPage_TitleText__36E11 {
        font-weight: 800;
        font-size: 30px;
        line-height: 32px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 20px;
        text-align: left;
        vertical-align: middle;
        color: white;
    }
    
    .MiddleBlockSecretPage_NormalText__3tzPN {
        font-weight: 300;
        font-size: 20px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 20px;
        text-align: left;
        vertical-align: middle;
        color: white;
    }
}
.BottomBlockSecretPage_Background__2RPoY {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color:#1f2021;
    /* background-color:#1f2021; */
    align-items: center;
    height: 800px;
}

.BottomBlockSecretPage_Column1__1zGOr {
    flex:1 1;
    min-width: 510px;
    max-width: 50%;
}

.BottomBlockSecretPage_Column2__1Zcwu {
    width: 500px;
    /* text-align: left; */
}


.BottomBlockSecretPage_TextTitle__2SJ5K {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 33px;
    /* text-transform: capitalize; */
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:white;
    /* background-color: red; */
    width: 100%;
}

.BottomBlockSecretPage_Text__aR8X3 {
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:white;
    /* background-color: red; */
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.BottomBlockSecretPage_TextBold__3xbtg {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:white;
    /* background-color: red; */
    width: 100%;
}

.BottomBlockSecretPage_TextSub__3cIC5 {
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:white;
    /* background-color: red; */
    width: 100%;
    padding: 10px;
}

.BottomBlockSecretPage_Kader__2x1qd {
    width: 50%;
    text-align: center;
    /* background-color: blue; */
    flex: 1 1;
    height: 80px;
    margin-left: 40%;
    max-width: 310px;
    min-width: 310px;
}

.BottomBlockSecretPage_Title__1xIdy{
    flex:1 1;
    text-align: center;
    align-items: center;
    /* background-color: gray; */
    padding-top: 60px;
    height: 100px;
    margin-bottom: 30px;
}

.BottomBlockSecretPage_Step1__3edBA{
    flex:1 1;
    text-align: end;
    align-items: center;
    /* background-color: green; */
    margin-bottom: 40px;
}

.BottomBlockSecretPage_Step2__9Iv1e{
    flex:1 1;
    text-align: end;
    align-items: center;
    /* background-color: green; */
    margin-bottom: 70px;
}

.BottomBlockSecretPage_Step3__1z6af{
    flex:1 1;
    text-align: end;
    align-items: center;
    /* background-color: green; */
    margin-bottom: 40px;
}

.BottomBlockSecretPage_Step4__2NTU3{
    flex:1 1;
    text-align: end;
    align-items: center;
    /* background-color: gray; */
    height: 110px;
    margin-bottom: 0px;
}

.BottomBlockSecretPage_Icon__1q5Bu {
    height: 20px;
}

.BottomBlockSecretPage_IconImage__A46vS {
    height: 30px;
}

.BottomBlockSecretPage_TextKader__Yt1N4 {
    width: 100%;
    background-color:#3d4042;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 4px 4px #000000a6;
}
.BottomBlockSecretPage_SubTextKader__1wtTL {
    width: 100%;
}

.BottomBlockSecretPage_ImageIphone__1QO54 {
    height: 700px;
    position: absolute;
    z-index: 5;
    margin-bottom: 100px;
    /* margin-right: 10%; */
    left: 50px;
}

.BottomBlockSecretPage_ImageScreen__1WreV {
    height: 281px;
    background-color: black;
    position: absolute;
}

.BottomBlockSecretPage_Video__HEf0S {
    margin-top: 60px;
    height: 600px;
    margin-left: 60px;
    border-radius: 10px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    /* background-color: red; */
}

@media only screen and (max-width: 768px) {
    .BottomBlockSecretPage_Background__2RPoY {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        /* background-color:red; */
        background-color:#1f2021;
        align-items: center;
        height: 650px;
        margin-top: -50px;
    }

    .BottomBlockSecretPage_Column1__1zGOr {
        flex:1 1;
        /* width: 300px;
        min-width: 100px; */
        /* max-width: 50%; */
        min-width: 350px;
        align-items: center;
        /* background-color: aqua; */
    }

    .BottomBlockSecretPage_Kader__2x1qd {
        width: 60%;
        text-align: center;
        /* background-color: blue; */
        flex: 1 1;
        height: 80px;
        margin-left: 6%;
        max-width: 310px;
        min-width: 310px;
    }

    .BottomBlockSecretPage_Title__1xIdy{
        flex:1 1;
        text-align: center;
        align-items: center;
        /* background-color: gray; */
        padding-top: 60px;
        height: 100px;
        margin-bottom: 30px;
    }

    .BottomBlockSecretPage_Step1__3edBA{
        flex:1 1;
        text-align: center;
        align-items: center;
        /* background-color: green; */
        margin-bottom: 40px;
    }

    .BottomBlockSecretPage_Step2__9Iv1e{
        flex:1 1;
        text-align: center;
        align-items: center;
        /* background-color: green; */
        margin-bottom: 70px;
    }

    .BottomBlockSecretPage_Step3__1z6af{
        flex:1 1;
        text-align: center;
        align-items: center;
        /* background-color: green; */
        margin-bottom: 40px;
    }

    .BottomBlockSecretPage_Step4__2NTU3{
        flex:1 1;
        text-align: center;
        align-items: center;
        /* background-color: gray; */
        height: 110px;
        margin-bottom: 0px;
    }

    .BottomBlockSecretPage_ImageIphone__1QO54 {
        height: 0px;
        position: absolute;
        z-index: 5;
        margin-bottom: 0px;
        /* margin-right: 10%; */
        left: -30px;
    }
    
    .BottomBlockSecretPage_ImageScreen__1WreV {
        height: 0px;
        background-color: black;
        position: absolute;
    }
    
    .BottomBlockSecretPage_Video__HEf0S {
        margin-top: 00px;
        height: 0px;
        margin-left: 0px;
        border-radius: 10px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        /* background-color: red; */
    }

    .BottomBlockSecretPage_Column2__1Zcwu {
        width: 400px;
        /* text-align: left; */
        align-items: center;
        /* background-color: aliceblue; */
        height: 0px;
    }

}

.HelpSecretPage_Background__3-Qjs {
  width: 100%;
  display: flex;
  background-color:#1f2021;
  height: 500px;
  align-items: center;
  justify-content: center;
}

.HelpSecretPage_BigBlock__24fRl{
  width: 85%;
  display: flex;
  flex-direction: row;
  /* background-color:green; */
  height: 300px;
  align-items: center;
  border-radius: 10px;
}

.HelpSecretPage_Block1__2a9cO {
  flex:2 1;
  display: flex;
  flex-direction: column;
  background-color:#3d4042;
  height: 300px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;box-shadow: 0px 4px 4px #000000a6;
}

.HelpSecretPage_LeftRow1__-ZB9_ {
  flex:1.5 1;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 100px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  align-items: center;
}

.HelpSecretPage_LeftRow2__2uHAy {
  flex:1 1;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 100px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  align-items: center;
}

.HelpSecretPage_Block2__S_k1Q {
  flex:1 1;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 300px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 4px 0px #000000a6;
}

.HelpSecretPage_TitleText__1FZ_r {
  font-weight: 800;
  font-size: 38px;
  line-height: 41px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-left: 40px;
  text-align: left;
  vertical-align: middle;
  color: white;
}

.HelpSecretPage_Button1___qStL {
  background-color: #fac42b !important;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  font-size: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin: 5px;
  letter-spacing: -0.02em;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  margin-left: 40px;
  box-shadow: 0px 2px 2px #000000a6;
}

.HelpSecretPage_Button2__1cOsl:hover {
  color:white; 
}

.HelpSecretPage_Button1___qStL:hover {
  color:white; 
}

.HelpSecretPage_Button2__1cOsl {
  background-color: #fac42b !important;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  font-size: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin: 5px;
  letter-spacing: -0.02em;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  box-shadow: 0px 2px 2px #000000a6;
}

.HelpSecretPage_Image__1ydEy {
  height: 250px;
  width: 250px;
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .HelpSecretPage_Block2__S_k1Q {
    flex: 0 1;
    width: 0px;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .HelpSecretPage_Block1__2a9cO {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:#3d4042;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #000000a6;
    text-align: center;
  }
  
  .HelpSecretPage_LeftRow1__-ZB9_ {
    flex:1.2 1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    text-align: center;
  }
  
  .HelpSecretPage_LeftRow2__2uHAy {
    flex:1 1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
  }

  .HelpSecretPage_Image__1ydEy {
    height: 0px;
    width: 0px;
    margin-top: 0px;
  }

  .HelpSecretPage_Background__3-Qjs {
    width: 100%;
    display: flex;
    background-color:#1f2021;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    height: 300px;
  }

  .HelpSecretPage_TitleText__1FZ_r {
    font-weight: 800;
    font-size: 24px;
    line-height: 41px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-left: 10%;
    text-align: center;
    vertical-align: middle;
    color: white;
    align-self: center;
  }

  .HelpSecretPage_Button1___qStL {
    background-color: #fac42b !important;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 15px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    margin-left: 10%;
    box-shadow: 0px 2px 2px #000000a6;
  }

  .HelpSecretPage_Button2__1cOsl {
    background-color: #fac42b !important;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 15px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    box-shadow: 0px 2px 2px #000000a6;
  }
}

@media only screen and (max-width: 390px) {
  .HelpSecretPage_Block2__S_k1Q {
    flex: 0 1;
    width: 0px;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .HelpSecretPage_Block1__2a9cO {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:#3d4042;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #000000a6;
    text-align: center;
  }
  
  .HelpSecretPage_LeftRow1__-ZB9_ {
    flex:1.2 1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    text-align: center;
  }
  
  .HelpSecretPage_LeftRow2__2uHAy {
    flex:1 1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
  }

  .HelpSecretPage_Image__1ydEy {
    height: 0px;
    width: 0px;
    margin-top: 0px;
  }

  .HelpSecretPage_Background__3-Qjs {
    width: 100%;
    display: flex;
    background-color:#1f2021;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    height: 300px;
  }

  .HelpSecretPage_TitleText__1FZ_r {
    font-weight: 800;
    font-size: 24px;
    line-height: 41px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-left: 10%;
    text-align: center;
    vertical-align: middle;
    color: white;
    align-self: center;
  }

  .HelpSecretPage_Button1___qStL {
    background-color: #fac42b !important;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 12px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    margin-left: 10%;
    box-shadow: 0px 2px 2px #000000a6;
  }

  .HelpSecretPage_Button2__1cOsl {
    background-color: #fac42b !important;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 12px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    box-shadow: 0px 2px 2px #000000a6;
  }
}
.AppLinkSecretPage_Background__3euu8 {
  width: 100%;
  display: flex;
  background-color:#1f2021;
  height: 00px;
  align-items: center;
  justify-content: center;
}

.AppLinkSecretPage_BigBlock__3tKkG{
  width: 85%;
  display: flex;
  flex-direction: row;
  /* background-color:green; */
  height: 000px;
  align-items: center;
  border-radius: 10px;
}

.AppLinkSecretPage_Block1__23V8h {
  flex:2 1;
  display: flex;
  flex-direction: column;
  background-color:#3d4042;
  height: 000px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;box-shadow: 0px 4px 4px #000000a6;
}

.AppLinkSecretPage_LeftRow1__mcQB- {
  flex:1.5 1;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 000px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  align-items: center;
}

.AppLinkSecretPage_LeftRow2__1-mjL {
  flex:1 1;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 000px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  align-items: center;
}

.AppLinkSecretPage_Block2__26-Az {
  flex:1 1;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 000px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 4px 0px #000000a6;
}

.AppLinkSecretPage_TitleText__1Wcae {
  font-weight: 800;
  font-size: 0px;
  line-height: 0px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-left: 40px;
  text-align: left;
  vertical-align: middle;
  color: white;
}

.AppLinkSecretPage_Button1__3K4T0 {
  background-color: #fac42b !important;
  padding-left: 20px;
  padding-right: 20px;
  height: 00px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  font-size: 00px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin: 5px;
  letter-spacing: -0.02em;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  margin-left: 40px;
  box-shadow: 0px 2px 2px #000000a6;
}

.AppLinkSecretPage_Button2__3pCb2:hover {
  color:white; 
}

.AppLinkSecretPage_Button1__3K4T0:hover {
  color:white; 
}

.AppLinkSecretPage_Button2__3pCb2 {
  background-color: #fac42b !important;
  padding-left: 20px;
  padding-right: 20px;
  height: 00px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  font-size: 00px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin: 5px;
  letter-spacing: -0.02em;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  box-shadow: 0px 2px 2px #000000a6;
}

.AppLinkSecretPage_Image__3vAMJ {
  height: 250px;
  width: 250px;
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .AppLinkSecretPage_Block2__26-Az {
    flex: 0 1;
    width: 0px;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .AppLinkSecretPage_Block1__23V8h {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:#3d4042;
    height: 180px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #000000a6;
    text-align: center;
  }
  
  .AppLinkSecretPage_LeftRow1__mcQB- {
    flex:1.2 1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    text-align: center;
  }
  
  .AppLinkSecretPage_LeftRow2__1-mjL {
    flex:1 1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
  }

  .AppLinkSecretPage_Image__3vAMJ {
    height: 0px;
    width: 0px;
    margin-top: 0px;
  }

  .AppLinkSecretPage_Background__3euu8 {
    width: 100%;
    display: flex;
    background-color:#1f2021;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    height: 300px;
  }

  .AppLinkSecretPage_TitleText__1Wcae {
    font-weight: 800;
    font-size: 24px;
    line-height: 41px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-left: 10%;
    text-align: center;
    vertical-align: middle;
    color: white;
    align-self: center;
  }

  .AppLinkSecretPage_Button1__3K4T0 {
    background-color: #fac42b !important;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 15px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    margin-left: 10%;
    box-shadow: 0px 2px 2px #000000a6;
  }

  .AppLinkSecretPage_Button2__3pCb2 {
    background-color: #fac42b !important;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 15px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    box-shadow: 0px 2px 2px #000000a6;
  }
}

@media only screen and (max-width: 390px) {
  .AppLinkSecretPage_Block2__26-Az {
    flex: 0 1;
    width: 0px;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .AppLinkSecretPage_Block1__23V8h {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:#3d4042;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #000000a6;
    text-align: center;
  }
  
  .AppLinkSecretPage_LeftRow1__mcQB- {
    flex:1.2 1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    text-align: center;
  }
  
  .AppLinkSecretPage_LeftRow2__1-mjL {
    flex:1 1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
  }

  .AppLinkSecretPage_Image__3vAMJ {
    height: 0px;
    width: 0px;
    margin-top: 0px;
  }

  .AppLinkSecretPage_Background__3euu8 {
    width: 100%;
    display: flex;
    background-color:#1f2021;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    height: 300px;
  }

  .AppLinkSecretPage_TitleText__1Wcae {
    font-weight: 800;
    font-size: 24px;
    line-height: 41px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-left: 10%;
    text-align: center;
    vertical-align: middle;
    color: white;
    align-self: center;
  }

  .AppLinkSecretPage_Button1__3K4T0 {
    background-color: #fac42b !important;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 12px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    margin-left: 10%;
    box-shadow: 0px 2px 2px #000000a6;
  }

  .AppLinkSecretPage_Button2__3pCb2 {
    background-color: #fac42b !important;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 12px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    box-shadow: 0px 2px 2px #000000a6;
  }
}
.TermsPage_middle__1bmVu {
    margin-left: 13.8%;
    margin-right: 13.8%;
    margin-top: 3%;
  }
.Terms_Columns__2PqIE {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Terms_Title__2b-MP {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-transform: capitalize;
  color: black;
}

.Terms_Subtitle__3RJP4 {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: black;
}

.Terms_DatumText__315na {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: gray;
}

.Terms_InfoText__13UGA {
  width: 80%;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 20px;
  color:black;
  margin-bottom: 75px;
}

.Terms_TextLink__1gtLO {
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: #fac42b !important;
}

@media only screen and (max-width: 768px) {
  .Terms_InfoText__13UGA {
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:black;
    margin-bottom: 75px;
  }
}
.OrderPage_middle__3G3aa {
  padding-left: 5.8%;
  padding-right: 5.8%;
  padding-top: 3%;
  background-color:#1f2021;
}

.Order_Rows__2L-jl {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.Order_Columns__3AwD0 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Order_OrderPicture__3VDFY {
  flex: 1 1;
}

.Order_OrderForm__3t5O1 {
  flex: 4 1;
  padding-left: 1%;
  padding-right: 1%;
}

.Order_OrderInfo__1kraE {
  flex: 3 1;
  padding-top: 35px;
}

.Order_inbetween__3OD2z {
  flex: 0.3 1;
}

@media only screen and (max-width: 768px) {
  .Order_Rows__2L-jl {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.OrderPicture_Columns__1DWNB {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.OrderPicture_Image__20udB {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  align-self: center;
}

.OrderPicture_Rating__1qCsA {
  margin-top: 15px;
  text-align: center;
}

.OrderPicture_StarName__2lzg1 {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white
}

.OrderPicture_StarInfo__qAfGh {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: rgb(173, 174, 181);
  margin-bottom: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .OrderPicture_Columns__1DWNB {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    margin-right: 0px;
  }
}
.OrderInfo_OrderInfo__1LJ5B {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 50px;
}

.OrderInfo_List__1e9_K {
  flex-direction: column;
  padding: 20px;
}

.OrderInfo_ListItem__2LpD1 {
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:black;
}

.OrderInfo_ListItemGrijs__25rpL {
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
  color:black
}

.OrderInfo_ListItemSpatie__8AyNr {
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;
  height: 20px;
  color:black
}

.OrderInfo_bancontact__3FOYC {
  width: 50px;
  height: 50px;
  align-self: center;
}

.OrderInfo_visa__3x3VZ{
  width: 50px;
  height: 50px;
  align-self: center;
  margin-right: 10px;
}

.OrderInfo_mastercard__occWw {
  width: 40px;
  height: 30px;
  align-self: center;
  margin-right: 10px;
}

.OrderInfo_amex__WKimF {
  width: 40px;
  height: 40px;
  align-self: center;
  background-color: transparent;
}

.OrderForm_Rows__113FH {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 62px;
}

.OrderForm_Columns__2CWE6 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.OrderForm_OrderForm__3--EJ {
  font-size: 17px!important;
  width: 100%;
}

.OrderForm_YellowButton__1WhJm {
  width: 100%;
}

.OrderForm_VideoFor__Q2J5o {
  border: 1px solid #8492a6;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  font-size: 17px!important;
}

.OrderForm_Option__18Dwy {
  border: none;
  font-weight: 400;
  font-size: 17px!important;
}

.OrderForm_For__1oyAj {
  padding-left: 1%;
  height: 45px;
  width: 45%;
  font-size: 17px!important;
}

.OrderForm_From__3NcAx {
  padding-right: 1%;
  height: 45px;
  width: 45%;
  font-size: 17px!important;
}

.OrderForm_Text__2HnbK {
  font-style: normal;
  font-weight: 900;
  font-size: 17px!important;
  line-height: 22px !important;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white;
}

.OrderForm_OccasionSelect__33ill {
  border: 1px solid #8492a6;
  box-sizing: border-box;
  font-size: 17px!important;
  border-radius: 5px;
  width: 100%;
  height: 45px;
}

.OrderForm_Email__1uUvx {
  padding-right: 1%;
  font-size: 17px!important;
  height: 45px;
  width: 45%;
}

.OrderForm_Gsm__2HEQe {
  height: 45px;
  font-size: 17px!important;
  padding-left: 1%;
  width: 45%;
}

.OrderForm_Checkboxdiv__GYCt3{
  margin-bottom: 30px;
  margin-top: 60px;
}

.OrderForm_TextVoorwaarden__3Vjzq {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 22px !important;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white;
}

.OrderForm_TextVoorwaardenClickbaar__1kQlS {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 22px !important;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: #fac42b !important;
}

.OrderForm_TextvoorwaardenDiv__3dc4V {
  height: 45px;
  padding-left: 1%;
  width: 100%;
  text-align: center;
  margin-bottom: 100px;
}

.OrderForm_Input__1mmWh {
  font-size: 17px!important;
}

@media only screen and (max-width: 768px) {
  .OrderForm_TextvoorwaardenDiv__3dc4V {
    height: 45px;
    padding-left: 1%;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
.HelpPage_middle__8O8WF {
    margin-left: 13.8%;
    margin-right: 13.8%;
    margin-top: 3%;
  }
.Help_Columns__3aGe- {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Help_Text__2KzSH {
  font-style: normal;
  font-weight: 900;
  font-size: 17px!important;
  line-height: 22px !important;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: black;
}

.Help_Input__4XYdG {
  font-size: 17px!important;
}

.Help_YellowButton__3ng36 {
  width: 60%;
}

.Help_Title__31WE6 {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-transform: capitalize;
  color: black;
}

.Help_Subtitle__3H1TC {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: black;
}

.Help_Completed__1_eSM {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fac42b !important;;
}

.Help_SubText__DFlNX {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: gray;
}

.Help_InfoText__2JD6J {
  width: 80%;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 20px;
  color:black;
  margin-bottom: 75px;
}


@media only screen and (max-width: 768px) {
  .Help_InfoText__2JD6J {
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:black;
    margin-bottom: 75px;
  }

  .Help_YellowButton__3ng36 {
    width: 100%;
  }
}
.EnrollPage_middle__Dfz_v {
    margin-left: 13.8%;
    margin-right: 13.8%;
    margin-top: 3%;
}

@media only screen and (max-width: 768px) {
  .EnrollPage_middle__Dfz_v {
    margin-left: 5.8%;
    margin-right: 5.8%;
    margin-top: 3%;
  }
}
.Enroll_Columns__1rHnK {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Enroll_Rows__3XaHt {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 62px;
}

.Enroll_Links__2xLy4 {
  padding-right: 1%;
  font-size: 17px!important;
  height: 10px;
  width: 48%;
}
  
.Enroll_Rechts__1XU9U {
  height: 10px;
  font-size: 17px!important;
  padding-left: 1%;
  width: 48%;
}

.Enroll_Text__2MH9i {
  font-style: normal;
  font-weight: 900;
  font-size: 17px!important;
  line-height: 22px !important;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: black;
}

.Enroll_Input__1r-rw {
  font-size: 17px!important;
  border-color: white;
}

.Enroll_YellowButton__1gNCA {
  width: 100%;
}

.Enroll_Title__2F9fo {
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  color: black;
}

.Enroll_Subtitle__2AFnp {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: black;
}

.Enroll_Completed__3hOQY {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fac42b !important;;
}

.Enroll_SubText__3eZuw {
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  color: gray;
}

.Enroll_InfoText__3E7O8 {
  width: 80%;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 20px;
  color:black;
  margin-bottom: 75px;
}


@media only screen and (max-width: 768px) {
  .Enroll_InfoText__3E7O8 {
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:black;
    margin-bottom: 75px;
  }

  .Enroll_YellowButton__1gNCA {
    width: 100%;
  }
}
.PaidPage_middle__9NlRq {
    margin-left: 13.8%;
    margin-right: 13.8%;
    margin-top: 3%;
  }
.Paid_Columns__37hoW {
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
}

.Paid_Title__1lf2X {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    text-transform: capitalize;
    color: black;
}

.Paid_Subtitle__3PgCs {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    color: black;
}

.Paid_DatumText__16E13 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: gray;
  }

.Paid_InfoText__3F7s0 {
    width: 80%;
    font-weight: 200;
    font-size: 16px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:black;
    margin-bottom: 75px;
}

.Paid_Link__1ZNaQ {
    width: 80%;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:#fac42b !important;
    margin-bottom: 75px;
}

@media only screen and (max-width: 768px) {
    .Paid_InfoText__3F7s0 {
        width: 100%;
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-top: 20px;
        color:black;
        margin-bottom: 75px;
    }
    .Paid_Image__34YbH {
        width: 100%;

    }
}

.Payment_middle__1rNr2 {
  margin-left: 20.8%;
  margin-right: 20.8%;
  margin-top: 3%;
}

@media only screen and (max-width: 768px) {
.Payment_middle__1rNr2 {
  margin-left: 10.8%;
  margin-right: 10.8%;
  margin-top: 3%;
}
}

  
.Checkoutmandje_OrderInfo__1Jdg3 {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 50px;
  margin-top: 10px;
}

.Checkoutmandje_List__23wLy {
  flex-direction: row;
  padding: 15px;
}

.Checkoutmandje_Column__3cIKS {
  padding-left: 110px;
  flex-direction: row;
}

.Checkoutmandje_TitleDiv__2Drw3 {
  flex-direction: row;
}

.Checkoutmandje_Image__1yZOM {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  align-self: center;
  position: absolute;
}

.Checkoutmandje_PaymentTitle__miYSF {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:black;
  margin-top: 20px;
}

.Checkoutmandje_PaymentSubTitle__3b0lL {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:rgb(36, 36, 36);
}

.Checkoutmandje_ListItemNaam__1uqUn {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color:black;
}

.Checkoutmandje_ListItem__2zUUs {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color:black;
}

.Checkoutmandje_ListItemTotal__1pLKM {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  /* or 133% */
  color:black;
}

.Checkoutmandje_ListItemPrice__1BDQs {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color:black;
  text-align: end;
}

.Checkoutmandje_ListItemOcassion__1-0w_ {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color:black;
}

.Checkoutmandje_Rows__2eJwQ {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.Checkoutmandje_Columns__1xajf {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.CheckoutInfo_OrderInfo__ZQL-4 {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 50px;
  margin-top: 10px;
}
  
.CheckoutInfo_List__3G-JL {
  flex-direction: row;
  padding: 15px;
}

.CheckoutInfo_Column__14EeS {
  flex-direction: row;
}

.CheckoutInfo_TitleDiv__1tiV_ {
  flex-direction: row;
}

.CheckoutInfo_PaymentTitle__1YFR_ {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:black;
  margin-top: 20px;
}

.CheckoutInfo_PaymentSubTitle__1P2w0 {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:rgb(36, 36, 36);
}

.CheckoutInfo_ListDetailTitle__HQg9a {
  font-weight: 800;
  font-size: 14px;
  line-height: 23px;
  color:rgb(97, 95, 95);
}

.CheckoutInfo_ListDetail__v3zNT{
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color:black;
}

.CheckoutPayment_OrderInfo__25n29 {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 50px;
  margin-top: 10px;
  align-items: center;
  border-color: transparent;
}

.CheckoutPayment_List__2kICU {
  flex-direction: row;
  padding: 15px;
}

.CheckoutPayment_TitleDiv__2PAGD {
  flex-direction: row;
}

.CheckoutPayment_PaymentSubTitle__3oD7z {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:rgb(36, 36, 36);
}

.CheckoutPayment_CardInfo__3OgXt {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:rgb(36, 36, 36);
  padding-top:20px;
  padding-bottom: 20px;
  padding-left: 10;
  text-align: left;
}

.CheckoutPayment_form__3q0GU{
    align-items: center;
    padding: 10px;
}

.CheckoutPayment_Column__3uySu{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 15px;
  background-color: white;
  border-color: transparent;
}

.CheckoutPayment_Cards__2dTeA{
    margin-top: 5px;
    align-items: end;
    margin-right: 5px;
}

.CheckoutPayment_bancontact__rDZvi {
  width: 50px;
  height: 50px;
  align-self: center;
}

.CheckoutPayment_visa__LqkGd{
  width: 50px;
  height: 50px;
  align-self: center;
  margin-right: 10px;
}

.CheckoutPayment_mastercard__2Kx8x {
  width: 40px;
  height: 30px;
  align-self: center;
  margin-right: 10px;
}

.CheckoutPayment_amex__26PoY {
  width: 40px;
  height: 40px;
  align-self: center;
  background-color: transparent;
}

.CheckoutPayment_Button__3ALYK {
  color: #ffffff !important;
  text-shadow: rgba(0,0,0,.12);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  background: #fac42b !important;
  border-radius: 22px;
  border-color: transparent !important;
  height: 44px;
  width:90%;
  justify-self: flex-end;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 5%;
}


