@import "~antd/dist/antd.css";
/* nunito-900 - latin */

body {
  margin: 0px;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 100%;
}
.ant-input:hover {
  border-color: rgb(250, 196, 43);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: rgb(250, 196, 43);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgb(250, 196, 43, 0.4);
}

.ant-input {
  border: 1px solid #8492a6;
  box-sizing: border-box;
  height: 45px;
  border-radius: 5px;
}

.ant-select-selection-item {
  line-height: 45px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(250, 196, 43);
}

.ant-checkbox-checked::after {
  border: 1px solid rgb(250, 196, 43);
}
.ant-input-affix-wrapper:hover {
  border-color: rgb(250, 196, 43);
}
