.Rows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 62px;
}

.Columns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.OrderForm {
  font-size: 17px!important;
  width: 100%;
}

.YellowButton {
  width: 100%;
}

.VideoFor {
  border: 1px solid #8492a6;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  font-size: 17px!important;
}

.Option {
  border: none;
  font-weight: 400;
  font-size: 17px!important;
}

.For {
  padding-left: 1%;
  height: 45px;
  width: 45%;
  font-size: 17px!important;
}

.From {
  padding-right: 1%;
  height: 45px;
  width: 45%;
  font-size: 17px!important;
}

.Text {
  font-style: normal;
  font-weight: 900;
  font-size: 17px!important;
  line-height: 22px !important;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white;
}

.OccasionSelect {
  border: 1px solid #8492a6;
  box-sizing: border-box;
  font-size: 17px!important;
  border-radius: 5px;
  width: 100%;
  height: 45px;
}

.Email {
  padding-right: 1%;
  font-size: 17px!important;
  height: 45px;
  width: 45%;
}

.Gsm {
  height: 45px;
  font-size: 17px!important;
  padding-left: 1%;
  width: 45%;
}

.Checkboxdiv{
  margin-bottom: 30px;
  margin-top: 60px;
}

.TextVoorwaarden {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 22px !important;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white;
}

.TextVoorwaardenClickbaar {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 22px !important;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: #fac42b !important;
}

.TextvoorwaardenDiv {
  height: 45px;
  padding-left: 1%;
  width: 100%;
  text-align: center;
  margin-bottom: 100px;
}

.Input {
  font-size: 17px!important;
}

@media only screen and (max-width: 768px) {
  .TextvoorwaardenDiv {
    height: 45px;
    padding-left: 1%;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}