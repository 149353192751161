.middle {
    margin-left: 13.8%;
    margin-right: 13.8%;
    margin-top: 3%;
}

@media only screen and (max-width: 768px) {
  .middle {
    margin-left: 5.8%;
    margin-right: 5.8%;
    margin-top: 3%;
  }
}