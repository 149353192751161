.Background {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    /* background-color:#1f2021; */
    background-color:#1f2021;
    align-items: center;
    height: 600px;
    padding-bottom: 100px;
}

.Frame1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    /* background-color:#1f2021; */
    background-color:#1f2021;
    height: 500px;
    align-items: center;
}

.Row1 {
    flex: 1;
    width: 90%;
    display: flex;
    margin-left: 50px;
    height: 30%;
    margin-top: 10px;
    align-items: center;
    vertical-align: center;
}

.Row3 {
    flex: 1;
    width: 90%;
    display: flex;
    margin-left: 50px;
    height: 30%;
    margin-top: 10px;
    align-items: center;
    vertical-align: center;
    /* background-color: red; */
}

.Row4 {
    flex: 1;
    width: 90%;
    display: flex;
    margin-left: 50px;
    height: 30%;
    /* margin-top: 10px; */
    align-items: center;
    vertical-align: center;
    /* background-color: blue; */
}

.Column1 {
    flex: 1;
    height: 340px;
    /* background-color: blue; */
}

.Column2 {
    height: 340px;
    align-items: center;
    padding-right: 100px;
}

.Text1 {
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
    text-align: left;
    vertical-align: middle;
    color: white;
    padding: 20px;
}

.Text2 {
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
    text-align: left;
    vertical-align: middle;
    color: white;
    padding: 20px;
}

.InviteCodeText {
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 60px;
    text-align: left;
    vertical-align: middle;
    color: white;
    padding-left: 20px;
}

.InviteCodeTextBig {
    font-weight: 800;
    font-size: 20px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    text-align: left;
    color: white;
}

.Image1 {
    height: 68px;
    background-color: #1f2021;
    margin-left: 20px;
}

.Image2 {
    height: 100px;
    background-color: #1f2021;
}

.Image3 {
    height: 400px;
    text-align: left;
}

.Video {
    height: 281px;
    background-color: black;
    position: absolute;
    bottom: 368px;
    width: 460px;
    left: 63px;
}

.VideoScreen {
    height: 281px;
    background-color: black;
    position: absolute;
}

@media only screen and (max-width: 768px) {
    .Frame1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        height: 500px;
        align-items: center;
    }

    .Column1 {
        flex: 1;
        height: 340px;
        /* background-color: blue; */
    }

    .Text1 {
        font-weight: 800;
        font-size: 40px;
        line-height: 42px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 70px;
        text-align: center;
        vertical-align: middle;
        color: white;
        padding: 50px;
    }

    .Text2 {
        font-weight: 300;
        font-size: 22px;
        line-height: 28px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 20px;
        text-align: center;
        vertical-align: middle;
        color: white;
        padding: 20px;
    }

    .Image1 {
        height: 45px;
        align-self: center;
    }
    
    .Image2 {
        height: 66px;
        background-color: #1f2021;
    }

    .Row1 {
        flex: 1;
        width: 80%;
        display: flex;
        margin-left: 0px;
        height: 30%;
        margin-top: 10px;
        align-items: center;
        vertical-align: center;
        text-align: center;
        /* background-color: red; */
    }

    .Row3 {
        flex: 1;
        width: 100%;
        display: flex;
        margin-left: 0;
        height: 30%;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        /* background-color: aqua; */
    }

    .Row4 {
        flex: 1;
        width: 100%;
        display: flex;
        margin-left: 0;
        height: 30%;
        align-items: center;
        justify-content: center;
        /* background-color: aqua; */
        padding-bottom: 50px;
    }

    .Badge1 {
        flex:1;
        /* background-color: aliceblue; */

    }

    .Badge2 {
        flex:1;
        align-self: center;
        /* background-color: blue; */
    }

    .Row1 {
        flex: 1;
        width: 100%;
        display: flex;
        height: 30%;
        margin-top: 10px;
        align-items: center;
        vertical-align: center;
    }

    .Video {
        height: 180px;
        background-color: black;
        position: absolute;
       bottom: 335px;
       width: 284px;
       left: 42px;
    }
    
    .VideoScreen {
        height: 281px;
        background-color: black;
        position: absolute;
        
    }

    .Column2 {
        height: 340px;
        align-items: center;
        padding: 20px;
        padding-bottom: 100px;
        /* background-color: aqua; */
    }

    .Image3 {
        height: 250px;
        text-align: left;
        /* background-color: red; */
    }

    .Background {
        width: 100%;
        display: flex;
        flex-direction: row;
        text-align: center;
        /* background-color:#1f2021; */
        background-color:#1f2021;
        align-items: center;
        height: 700px;
    }

    .InviteCodeText {
        font-weight: 300;
        font-size: 17px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 60px;
        text-align: right;
        vertical-align: middle;
        color: white;
        padding-left: 20px;
    }
    
    .InviteCodeTextBig {
        font-weight: 800;
        font-size: 20px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        text-align: right;
        color: white;
    }
}