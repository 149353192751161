.OrderInfo {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 50px;
  margin-top: 10px;
  align-items: center;
  border-color: transparent;
}

.List {
  flex-direction: row;
  padding: 15px;
}

.TitleDiv {
  flex-direction: row;
}

.PaymentSubTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:rgb(36, 36, 36);
}

.CardInfo {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:rgb(36, 36, 36);
  padding-top:20px;
  padding-bottom: 20px;
  padding-left: 10;
  text-align: left;
}

.form{
    align-items: center;
    padding: 10px;
}

.Column{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 15px;
  background-color: white;
  border-color: transparent;
}

.Cards{
    margin-top: 5px;
    align-items: end;
    margin-right: 5px;
}

.bancontact {
  width: 50px;
  height: 50px;
  align-self: center;
}

.visa{
  width: 50px;
  height: 50px;
  align-self: center;
  margin-right: 10px;
}

.mastercard {
  width: 40px;
  height: 30px;
  align-self: center;
  margin-right: 10px;
}

.amex {
  width: 40px;
  height: 40px;
  align-self: center;
  background-color: transparent;
}

.Button {
  color: #ffffff !important;
  text-shadow: rgba(0,0,0,.12);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  background: #fac42b !important;
  border-radius: 22px;
  border-color: transparent !important;
  height: 44px;
  width:90%;
  justify-self: flex-end;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 5%;
}

