.Rows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 150px;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid  white;
}

.Columns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-right: 20%;
}

.StarName {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: black;
}

@media only screen and (max-width: 768px) {
  .Rows {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 150px;
    align-items: center;
    border: 0px ;
    width: 100%;
    padding-left: 5px;
  }

  .Columns {
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 50px;
    margin-right: 0;
    margin-left: 6%;
  }

  .StarName {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: black;
    text-align: center;
    margin-left: 2%;
  }
  
  .InfoStars {
    width: 100%;
    overflow: auto;
    text-overflow: ellipsis;
    text-align: center;
  }
}