.middle {
    padding-left: 13.8%;
    padding-right: 13.8%;
    padding-top: 3%;
    background-color:#1f2021;
}

@media only screen and (max-width: 768px) {
  .middle {
    padding-left: 8.8%;
    padding-right: 8.8%;
    padding-top: 3%;
    background-color:#1f2021;
  }
}
