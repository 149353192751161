.middle {
  padding-left: 13.8%;
  padding-right: 13.8%;
  padding-top: 3%;
  background-color:#1f2021;
  height: 1500px;
  text-align: center;
}

.Image {
  margin-top: 200px;
  text-align: center;
}

.Button {
  background-color: transparent;
  border: 0px;
}

.Input {
  margin-top: 20px;
  border-radius: 5px;
  font-size: large;
  font-weight: 700;
  background-color: gray;
  border-radius: 20px;
  border: 3px solid white;
  color: white;
  width: 200px;
}