.Rows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 150px;
  background-color: #484848;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid  white;
  box-shadow: blue;
}
.Stars {
  margin: 4px;

}
.Stars1 {
  margin-right: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.TextArea {
  height: 0px;
  width: 0px;
}

.Icons {
  margin: 10px;
}

.Icons1 {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Columns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.ColumnsReview {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: 25px;
  width: 400px;
  overflow: auto;
  text-overflow: ellipsis;
}

.Image {
  width: 340px;
  height: 450px;
  border-radius: 20px;
}

.Rating {
  margin-top: 20px;
  margin-left: auto;
}

.InfoText {
  margin-left: 25px;
  width: 400px;
  overflow: auto;
  text-overflow: ellipsis;
}

.InfoStars {
  width: 400px;
  overflow: auto;
  text-overflow: ellipsis;
}

.StarName {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white;
}

.StarShow {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: rgb(173, 174, 181);
  margin-bottom: 5px;
}

.StarInfo {
  height: 215px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  /* margin-top: 20px; */
  color:white;
  margin-bottom: 75px;
}

.YellowButton {
  margin-top: 20px;
  width: 40%;
  text-align: center;
  margin-left: 60%;
}

.Input {
  font-size: 17px!important;
  height: 100px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .Rows {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 150px;
    align-items: center;
    border: 0px ;
    width: 100%;
    padding-left: 5px;
  }

  .Columns {
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  .ColumnsReview {
    margin-bottom: 50px;
    width: 100%
  }

  .StarName {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: white;
    text-align: center;
  }

  .StarInfo {
    height:180px;
    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
  }

  .YellowButton {
    margin-top: 20px;
    width: 40%;
    text-align: center;
    margin-left: 60%;
  }
  
  .InfoStars {
    width: 100%;
    overflow: auto;
    text-overflow: ellipsis;
    text-align: center;
  }

  .StarShow {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 33px;
    text-transform: capitalize;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: rgb(173, 174, 181);
    text-align: center;
  }

  .Icons {
    margin-right: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
}