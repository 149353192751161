.Card {
  width: 184px;
  height: 240px;
  display: flex;
  flex-direction: column;
  border-width: 4px;
  background-color: #1f2021;
  border-color: #1f2021;
}

.ImageWrapper {
  height: 77%;
  position: relative;
}

.Image {
  min-width: 184px;
  max-width: 184px;
  border-radius: 10px 10px 10px 10px;
  background-color: #1f2021;
}

.hart {
  position: absolute;
  text-align: center;
  min-width: 4rem;
  right: 0.1rem;
  top: 0.1rem;
  width: 20px;
  height: 60px;
}

.Box {
  width: 101%;
  height: 23%;
  background-color: #1f2021;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Name {
  font-style: normal;
  font-weight: 800;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif;
  font-size: 18px;
  line-height: 20px;
  text-transform: capitalize;
  margin-top: 4%;
  margin-left: 1%;
  margin-bottom: 5px;
  color: white;
}

.Info {
  font-style: normal;
  font-weight: 900;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 22px;
  margin-left: 1%;
  height: 50px;
  background-color: #1f2021;
  text-transform: capitalize;
  color: rgb(173, 174, 181);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Price {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #ffffff;
  position: absolute;
  text-align: center;
  min-width: 4rem;
  right: 0.3rem;
  bottom: 0.4rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

/* @media only screen and (max-width: 1600px) {
  .Card {
    background-color: transparent;
  }
} */
