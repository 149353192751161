.Card {
  height: 622px;
  width: 350px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.Video {
  height: 622px;
  width: 350px;
  border-radius: 20px;
  background-color: black;
}

@media only screen and (max-width: 1000px) {
  .Card {
    height: 500px;
    width: 281.35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .Video {
    height: 500px;
    width: 281.35px;
    border-radius: 20px;
    background-color: black;
  }
}
  