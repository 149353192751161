.OrderInfo {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 50px;
  margin-top: 10px;
}
  
.List {
  flex-direction: row;
  padding: 15px;
}

.Column {
  flex-direction: row;
}

.TitleDiv {
  flex-direction: row;
}

.PaymentTitle {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:black;
  margin-top: 20px;
}

.PaymentSubTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:rgb(36, 36, 36);
}

.ListDetailTitle {
  font-weight: 800;
  font-size: 14px;
  line-height: 23px;
  color:rgb(97, 95, 95);
}

.ListDetail{
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color:black;
}
