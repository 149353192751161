.Button {
  color: #ffffff !important;
  text-shadow: rgba(0,0,0,.12);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  background: #fac42b !important;
  border-radius: 22px;
  border-color: transparent !important;
  height: 44px;
  width: 100%;
  justify-self: flex-end;
  justify-content: center;
}
