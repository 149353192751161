.middle {
  margin-left: 20.8%;
  margin-right: 20.8%;
  margin-top: 3%;
}

@media only screen and (max-width: 768px) {
.middle {
  margin-left: 10.8%;
  margin-right: 10.8%;
  margin-top: 3%;
}
}