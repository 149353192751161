.Rows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.Columns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.OrderPicture {
  flex: 1;
}

.OrderForm {
  flex: 4;
  padding-left: 1%;
  padding-right: 1%;
}

.OrderInfo {
  flex: 3;
  padding-top: 35px;
}

.inbetween {
  flex: 0.3;
}

@media only screen and (max-width: 768px) {
  .Rows {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}