.Columns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Image {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  align-self: center;
}

.Rating {
  margin-top: 15px;
  text-align: center;
}

.StarName {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white
}

.StarInfo {
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: rgb(173, 174, 181);
  margin-bottom: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .Columns {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    margin-right: 0px;
  }
}