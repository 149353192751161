.Background {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    background-color:#1f2021;
    /* background-color:#1f2021; */
    align-items: center;
    height: 800px;
}

.Column1 {
    flex:1;
    min-width: 510px;
    max-width: 50%;
}

.Column2 {
    width: 500px;
    /* text-align: left; */
}


.TextTitle {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 33px;
    /* text-transform: capitalize; */
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:white;
    /* background-color: red; */
    width: 100%;
}

.Text {
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:white;
    /* background-color: red; */
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.TextBold {
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:white;
    /* background-color: red; */
    width: 100%;
}

.TextSub {
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:white;
    /* background-color: red; */
    width: 100%;
    padding: 10px;
}

.Kader {
    width: 50%;
    text-align: center;
    /* background-color: blue; */
    flex: 1;
    height: 80px;
    margin-left: 40%;
    max-width: 310px;
    min-width: 310px;
}

.Title{
    flex:1;
    text-align: center;
    align-items: center;
    /* background-color: gray; */
    padding-top: 60px;
    height: 100px;
    margin-bottom: 30px;
}

.Step1{
    flex:1;
    text-align: end;
    align-items: center;
    /* background-color: green; */
    margin-bottom: 40px;
}

.Step2{
    flex:1;
    text-align: end;
    align-items: center;
    /* background-color: green; */
    margin-bottom: 70px;
}

.Step3{
    flex:1;
    text-align: end;
    align-items: center;
    /* background-color: green; */
    margin-bottom: 40px;
}

.Step4{
    flex:1;
    text-align: end;
    align-items: center;
    /* background-color: gray; */
    height: 110px;
    margin-bottom: 0px;
}

.Icon {
    height: 20px;
}

.IconImage {
    height: 30px;
}

.TextKader {
    width: 100%;
    background-color:#3d4042;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 4px 4px #000000a6;
}
.SubTextKader {
    width: 100%;
}

.ImageIphone {
    height: 700px;
    position: absolute;
    z-index: 5;
    margin-bottom: 100px;
    /* margin-right: 10%; */
    left: 50px;
}

.ImageScreen {
    height: 281px;
    background-color: black;
    position: absolute;
}

.Video {
    margin-top: 60px;
    height: 600px;
    margin-left: 60px;
    border-radius: 10px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    /* background-color: red; */
}

@media only screen and (max-width: 768px) {
    .Background {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        /* background-color:red; */
        background-color:#1f2021;
        align-items: center;
        height: 650px;
        margin-top: -50px;
    }

    .Column1 {
        flex:1;
        /* width: 300px;
        min-width: 100px; */
        /* max-width: 50%; */
        min-width: 350px;
        align-items: center;
        /* background-color: aqua; */
    }

    .Kader {
        width: 60%;
        text-align: center;
        /* background-color: blue; */
        flex: 1;
        height: 80px;
        margin-left: 6%;
        max-width: 310px;
        min-width: 310px;
    }

    .Title{
        flex:1;
        text-align: center;
        align-items: center;
        /* background-color: gray; */
        padding-top: 60px;
        height: 100px;
        margin-bottom: 30px;
    }

    .Step1{
        flex:1;
        text-align: center;
        align-items: center;
        /* background-color: green; */
        margin-bottom: 40px;
    }

    .Step2{
        flex:1;
        text-align: center;
        align-items: center;
        /* background-color: green; */
        margin-bottom: 70px;
    }

    .Step3{
        flex:1;
        text-align: center;
        align-items: center;
        /* background-color: green; */
        margin-bottom: 40px;
    }

    .Step4{
        flex:1;
        text-align: center;
        align-items: center;
        /* background-color: gray; */
        height: 110px;
        margin-bottom: 0px;
    }

    .ImageIphone {
        height: 0px;
        position: absolute;
        z-index: 5;
        margin-bottom: 0px;
        /* margin-right: 10%; */
        left: -30px;
    }
    
    .ImageScreen {
        height: 0px;
        background-color: black;
        position: absolute;
    }
    
    .Video {
        margin-top: 00px;
        height: 0px;
        margin-left: 0px;
        border-radius: 10px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        /* background-color: red; */
    }

    .Column2 {
        width: 400px;
        /* text-align: left; */
        align-items: center;
        /* background-color: aliceblue; */
        height: 0px;
    }

}
