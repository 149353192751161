.OrderInfo {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  margin-bottom: 50px;
  margin-top: 10px;
}

.List {
  flex-direction: row;
  padding: 15px;
}

.Column {
  padding-left: 110px;
  flex-direction: row;
}

.TitleDiv {
  flex-direction: row;
}

.Image {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  align-self: center;
  position: absolute;
}

.PaymentTitle {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:black;
  margin-top: 20px;
}

.PaymentSubTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:rgb(36, 36, 36);
}

.ListItemNaam {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color:black;
}

.ListItem {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color:black;
}

.ListItemTotal {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  /* or 133% */
  color:black;
}

.ListItemPrice {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color:black;
  text-align: end;
}

.ListItemOcassion {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color:black;
}

.Rows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.Columns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}