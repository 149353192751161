.OrderInfo {
    width: 100%;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 50px;
  }
  
  .List {
    flex-direction: column;
    padding: 20px;
    align-items: center;
    text-align: center;
    overflow: auto;
    text-overflow: ellipsis;
  }
  
  .ListItem {
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:black;
    text-align: center;
    min-width: 150px;
  }

  .ListItemLink {
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color:#fac42b;
    text-align: center;
  }
  
  .ListItemGrijs {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
    color:gray
  }
  
  .ListItemSpatie {
    font-style: normal;
    font-weight: 100;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
    height: 20px;
    color:black
  }
  
  .bancontact {
    width: 50px;
    height: 50px;
    align-self: center;
  }
  
  .visa{
    width: 50px;
    height: 50px;
    align-self: center;
    margin-right: 10px;
  }
  
  .mastercard {
    width: 40px;
    height: 30px;
    align-self: center;
    margin-right: 10px;
  }
  
  .amex {
    width: 40px;
    height: 40px;
    align-self: center;
    background-color: transparent;
  }

  .hart {
    position: relative;
    text-align: center;
    width: 30px;
    height: 40px;
    padding-bottom: 10px;
  }

@media only screen and (max-width: 768px) {
    .OrderInfo {
        width: 100%;
        border-radius: 15px;
        background-color: white;
        margin-bottom: 50px;
        align-self: center;
        }
}