@import url('https://fonts.googleapis.com/css?family=Roboto');

.Background {
  width: 100%;
  display: flex;
  background-color:#1f2021;
  height: 500px;
  align-items: center;
  justify-content: center;
}

.BigBlock{
  width: 85%;
  display: flex;
  flex-direction: row;
  /* background-color:green; */
  height: 300px;
  align-items: center;
  border-radius: 10px;
}

.Block1 {
  flex:2;
  display: flex;
  flex-direction: column;
  background-color:#3d4042;
  height: 300px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;box-shadow: 0px 4px 4px #000000a6;
}

.LeftRow1 {
  flex:1.5;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 100px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  align-items: center;
}

.LeftRow2 {
  flex:1;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 100px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  align-items: center;
}

.Block2 {
  flex:1;
  display: flex;
  flex-direction: row;
  background-color:#3d4042;
  height: 300px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 4px 0px #000000a6;
}

.TitleText {
  font-weight: 800;
  font-size: 38px;
  line-height: 41px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-left: 40px;
  text-align: left;
  vertical-align: middle;
  color: white;
}

.Button1 {
  background-color: #fac42b !important;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  font-size: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin: 5px;
  letter-spacing: -0.02em;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  margin-left: 40px;
  box-shadow: 0px 2px 2px #000000a6;
}

.Button2:hover {
  color:white; 
}

.Button1:hover {
  color:white; 
}

.Button2 {
  background-color: #fac42b !important;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 5px;
  font-weight: 700;
  color: white;
  font-size: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin: 5px;
  letter-spacing: -0.02em;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  box-shadow: 0px 2px 2px #000000a6;
}

.Image {
  height: 250px;
  width: 250px;
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .Block2 {
    flex: 0;
    width: 0px;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .Block1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:#3d4042;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #000000a6;
    text-align: center;
  }
  
  .LeftRow1 {
    flex:1.2;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    text-align: center;
  }
  
  .LeftRow2 {
    flex:1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
  }

  .Image {
    height: 0px;
    width: 0px;
    margin-top: 0px;
  }

  .Background {
    width: 100%;
    display: flex;
    background-color:#1f2021;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    height: 300px;
  }

  .TitleText {
    font-weight: 800;
    font-size: 24px;
    line-height: 41px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-left: 10%;
    text-align: center;
    vertical-align: middle;
    color: white;
    align-self: center;
  }

  .Button1 {
    background-color: #fac42b !important;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 15px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    margin-left: 10%;
    box-shadow: 0px 2px 2px #000000a6;
  }

  .Button2 {
    background-color: #fac42b !important;
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 15px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    box-shadow: 0px 2px 2px #000000a6;
  }
}

@media only screen and (max-width: 390px) {
  .Block2 {
    flex: 0;
    width: 0px;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  .Block1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:#3d4042;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #000000a6;
    text-align: center;
  }
  
  .LeftRow1 {
    flex:1.2;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    text-align: center;
  }
  
  .LeftRow2 {
    flex:1;
    display: flex;
    flex-direction: row;
    background-color:#3d4042;
    height: 100px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
  }

  .Image {
    height: 0px;
    width: 0px;
    margin-top: 0px;
  }

  .Background {
    width: 100%;
    display: flex;
    background-color:#1f2021;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    height: 300px;
  }

  .TitleText {
    font-weight: 800;
    font-size: 24px;
    line-height: 41px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-left: 10%;
    text-align: center;
    vertical-align: middle;
    color: white;
    align-self: center;
  }

  .Button1 {
    background-color: #fac42b !important;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 12px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    margin-left: 10%;
    box-shadow: 0px 2px 2px #000000a6;
  }

  .Button2 {
    background-color: #fac42b !important;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;
    border-radius: 5px;
    font-weight: 700;
    color: white;
    font-size: 12px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin: 5px;
    letter-spacing: -0.02em;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    box-shadow: 0px 2px 2px #000000a6;
  }
}