.IconGrote {
    color: rgb(151, 151, 167);
    font-size: 100px;
    line-height: 140px;
    margin-bottom: 50px;
    margin-top: 50px;
}

.IconHand {
    color: rgb(151, 151, 167);
    font-size: 30px;
    line-height: 140px;
    margin-top: 100px;
}

.Columns {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
}

.Row {
    flex: 1;
    flex-direction: column;
}

.Text {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
  
    text-align: center;
    vertical-align: middle;
    color: white;
}

@media only screen and (max-width: 768px) {
    .IconGrote {
        color: rgb(151, 151, 167);
        font-size: 66px;
        line-height: 93px;
        margin-bottom: 33px;
        margin-top: 33px;
    }

    .IconHand {
        color: rgb(151, 151, 167);
        font-size: 20px;
        line-height: 93px;
        margin-top: 66px;
    }

    .Text {
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 13px;
        text-align: center;
        vertical-align: middle;
        color: white;
    }
}