@import url('https://fonts.googleapis.com/css?family=Roboto');

.NavbarSearch {
  margin-left: auto;
  width: 35%;
  border: 3px solid white;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: #484848;
  height: 40px;
  align-items: center;
}

.NavbarSearch input{
  background-color: rgba(0,0,0,0);
  color: white;
} 

.NavbarHow {
  font-weight:600;
  font-size: 16px;
  line-height: 20px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  width: 270px;
  letter-spacing: -0.02em;
  text-align: center;
  vertical-align: middle;
  color: white;
}

.logo {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 40px;
  text-transform: capitalize;
  vertical-align: middle;
  color: #000000;
}

.logoimg {
  height: 40px;
  vertical-align: middle;
  margin-left: 20px;
}

.Header {
  text-align: center;
  background-color:#1f2021;
  z-index: 9;
}

.Prefix {
  color: white;
  font-size: 26px;
}

.HamburgerIcon {
  color: white;
  font-size: 28px;
}

.navbarcollapsecustom{
  flex-grow: 0 !important;
}


@media only screen and (max-width: 768px) {
  .navbarcollapsecustom{
    flex-grow: 1;
  }
  
  .logoimg {
    height: 40px;
    vertical-align: middle;
    margin-left: 0px;
  }
}