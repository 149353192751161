@import url('https://fonts.googleapis.com/css?family=Roboto');

.Row {
  flex-direction: column;
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
}

.Columns {
  flex-direction: row;
  display: flex;
  border: 5px solid  white;
  margin-bottom: 20px;
  border-radius: 30px;
}

.Div1 {
  margin: auto;
  width: 70%;
  height: 100%;
  padding-left: 15%;
  padding:50px;
}

.Div2 {
  margin: auto;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.IconGrote {
  color: white;
  font-size: 160px;
}

.Text {
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 100px;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  justify-self: center;
  color: white;
}

.Hoofding {
  width: 80%;
  font-weight: 800;
  font-size: 30px;
  line-height: 32px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 20px;
  color:white;
  margin-bottom: 25px;
}

@media only screen and (max-width: 768px) {
  .Row {
    flex-direction: column;
    width: 100%;
    display: flex;
    flex-wrap: nowrap; 
  }
  
  .Columns {
    flex-direction: row-reverse;
    display: flex;
    border: 5px solid  white;
    margin-bottom: 20px;
    border-radius: 30px;
  }
  
  .Div1 {
    margin: auto;
    width: 50%;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .Div2 {
    margin: auto;
    width: 100%;
    height: 100%;
    padding-left: 10%;
  }
  
  .IconGrote {
    color: white;
    font-size: 80px;
  }
  
  .Text {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 100px;
    text-align: center;
    vertical-align: middle;
    align-self: center;
    justify-self: center;
    color: white;
  }
  
  .Hoofding {
    width: 80%;
    font-weight: 800;
    font-size: 30px;
    line-height: 32px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 25px;
  }
}
