.Background {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color:#1f2021;
    padding-bottom: 100px;
}

.Column1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color:#1f2021;
    padding-left: 70px;
    padding-right: 100px;
}

.RowIcon{
    /* background-color: blue; */
    margin-bottom: 20px;
}

.IconImage {
    height: 50px;
    text-align: left;
}

.RowTitle{
    /* background-color: red; */
    margin-bottom: 20px;
    height: 50px;
}

.RowText{
    /* background-color: yellow; */
    text-align: left;

}

.Column2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color:#1f2021;
    padding-right: 100px;
}

.Column3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color:#1f2021;
    padding-right: 70px;
}

.TitleText {
    font-weight: 800;
    font-size: 30px;
    line-height: 32px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
    text-align: left;
    vertical-align: middle;
    color: white;
}

.NormalText {
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-bottom: 20px;
    text-align: left;
    vertical-align: middle;
    color: white;
}

@media only screen and (max-width: 768px) {
    .Background {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        align-items: center;
        /* height: 800px; */
        padding-top: 000px;
        padding-bottom: 1px;
    }
    
    .RowIcon{
        /* background-color: blue; */
        flex: 1;
        margin-bottom: 20px;
    }
    
    .IconImage {
        height: 50px;
    }
    
    .RowTitle{
        /* background-color: red; */
        flex: 1;
        margin-bottom: 20px;
    }
    
    .RowText{
        /* background-color: yellow; */
        flex: 1;
        text-align: center;
        min-width: 300px;
    }

    .Column1 {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        align-self: center;
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 60px;
    }
    
    .Column2 {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        align-self: center;
        padding-right: 30px;
        padding-left: 30px;
        margin-bottom: 60px;
    }
    .Column3 {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color:#1f2021;
        align-self: center;
        padding-right: 30px;
        padding-left: 30px;
    }
    
    .TitleText {
        font-weight: 800;
        font-size: 30px;
        line-height: 32px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 20px;
        text-align: left;
        vertical-align: middle;
        color: white;
    }
    
    .NormalText {
        font-weight: 300;
        font-size: 20px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-bottom: 20px;
        text-align: left;
        vertical-align: middle;
        color: white;
    }
}