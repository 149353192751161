.middle {
  padding-left: 5.8%;
  padding-right: 5.8%;
  padding-top: 3%;
  background-color:#1f2021;
}

@media only screen and (max-width: 768px) {
  .middle {
    padding-left: 5.8%;
    padding-right: 5.8%;
    padding-top: 3%;
    background-color:#1f2021;
  }   
}

@media only screen and (max-width: 1000px) {
  .middle {
    padding-left: 5.8%;
    padding-right: 5.8%;
    padding-top: 3%;
    background-color:#1f2021;
  }
}
