.Footer {
  background-color: #161616;
  padding: 4% 5.7% 4% 5.7%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 100%;
}

.Image{
  height: 17px;
  width: 17px;
}

.FooterMain {
  background-color: #161616;
  padding: 4% 5.7% 4% 5.7%;
  flex-direction: column;
  justify-content: space-between;
}

.Mail {
  flex: 1;
  color: white;
}

.Empty {
  flex: 1;
  color:white;
}

.Info {
  flex: 1;
  border-left: solid 1px;
  padding-top: 1%;
  padding-left: 3%;
  color:white;
}

.MailIcon{
  color:white;
}

.InputMail {
  border: 1px solid  #484848;;
  border-radius: 10px;
  background-color: #161616;
  color: white;
  align-items: center;

}
.InputMail input{
  background-color: rgba(0,0,0,0);
  color: white;
} 

.ArrowButton {
  color: white;
  padding-bottom: 8px;
}

.Row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
}
.PressButton{
  background-color: #161616;
  text-align: center;
  border: 1px solid  #484848;;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  margin-left: 20px;
} 

.PressButtonText{
  background-color: #161616;
  text-align: center;
  border: 1px solid  #484848;;
  border-radius: 10px;
  height: 60px;
  widows: 300px;
  align-self: center;
  justify-content: center;
  text-align: center;
  font-size: 26px;
  color: white;
} 

.Joinen{
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .Footer {
    background-color: #161616;
    padding: 4% 5.7% 4% 5.7%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 100%;
  }

  .Joinen{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .Mail {
    flex: 1;
    color: white;
    margin-bottom: 50px;
  }

  .Info {
    flex: 1;
    border-left: solid 1px;
    padding-top: 1%;
    padding-left: 3%;
    color:white;
    font-size: 14px;
  }
}