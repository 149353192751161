@import url('https://fonts.googleapis.com/css?family=Roboto');

.CardRow {
  margin-top: 4%;
  margin-bottom: 4%;
}

.Title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color:white;
}

.More {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: rgba(255, 255, 255, 1);
  margin-left: auto;
  margin-top: 5px;
}

.Top {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.Cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.Card {
  flex: 0 0 auto;
}

.Cards .Card:not(:last-child) {
  margin-right: 15px;
}
