@import url('https://fonts.googleapis.com/css?family=Roboto');

.Columns {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color:#1f2021;
}

.Title {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    /* text-transform: capitalize; */
    color: white;
}

.Subtitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
    /* text-transform: capitalize; */
    color: white;
}

.InfoText {
    width: 80%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
}

.InfoTextBold {
    width: 80%;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
}

.TextLink {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px !important;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: #fac42b !important;
  }

@media only screen and (max-width: 768px) {
    .InfoText {
        width: 100%;
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-top: 20px;
        color:white;
        margin-bottom: 75px;
    }
}
