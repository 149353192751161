@import url('https://fonts.googleapis.com/css?family=Roboto');

.Columns {
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
}

.Title {
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    text-transform: capitalize;
    color: black;
}

.Subtitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    color: black;
}

.DatumText {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: gray;
  }

.InfoText {
    width: 80%;
    font-weight: 200;
    font-size: 16px;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:black;
    margin-bottom: 75px;
}

.Link {
    width: 80%;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:#fac42b !important;
    margin-bottom: 75px;
}

@media only screen and (max-width: 768px) {
    .InfoText {
        width: 100%;
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
        margin-top: 20px;
        color:black;
        margin-bottom: 75px;
    }
    .Image {
        width: 100%;

    }
}
