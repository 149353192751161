.Card {
  height: 500px;
  width: 281.35px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-color: black;
  margin-left: 20px;
}

.Video {
  height: 500px;
  width: 281.35px;
  border-radius: 20px;
  background-color: black;
}

