.Rows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 150px;
  
}

.Button {
  height: 50px;
}

.Text {
  flex-direction: column;
  margin-left: 30px;
  margin-right: 10px;
  max-height: 500px;
  max-width: 400px;
}

.Columns {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.ColumnsGoedDoel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-right: 0.1%;
  margin-left: 2%;
  min-width: 250px;
}

.InfoText {
  margin-left: 25px;
  width: 380px;
  min-width: 300px;
  overflow: auto;
  text-overflow: ellipsis;
  max-height: 622px;
  max-width: 400px;
}

.StarName {
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: white;
}

.StarShow {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  color: rgb(173, 174, 181);
}

.StarInfo {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin-top: 20px;
  color:white;
  margin-bottom: 75px;
}

.YellowButton {
  margin-top: 0px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .Rows {
    flex-direction: column;
    margin-bottom: 150px;
    width: 100%;
    align-items: center;
    padding-right: 5%;
  }

  .Columns {
    width: 100%;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
  }

  .StarInfo {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
  }

  .YellowButton {
    margin-top: 50px;
    text-align: center;
    width: 100%;
  }
  
  .InfoText {
    margin-left: 25px;
    width: 380px;
  }

  .ColumnsGoedDoel {
    margin-top: 50px;
    width: 100%;
    align-items: center;
  }

  .Text {
    flex-direction: column;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .StarInfo {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    margin-top: 20px;
    color:white;
    margin-bottom: 75px;
  }
  .InfoText {
    margin-left: 25px;
    width: 300px;
    min-width: 250px;
    overflow: auto;
    text-overflow: ellipsis;
    height: 500px;
  }
}